<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>
    <div class="col-12 col-md-12">
      <UserBar :period="period" :OPAnalyst="OPAnalyst" v-on:changePeriod="updatePeriod($event)"></UserBar>
    </div>
    <div class="row col-md-12 col-sm-12 col-12">
      <div class="card col-12">
        <div class="card-header border-0">
          <div class="col-8">
            <h3>Exportação Marítima</h3>
            <i class="expl">Atividades concluídas em processos</i>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="row col-12 px-2 pt-4 pb-2 bt-1 m-0">
            <div class="col-4 px-0 text-center br1 text-left">
              <div class="col-12 text-left mb-3">
                Pré embarque
              </div>
              <div v-for="item in activitiesData.activitiesPreE" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Pós embarque
              </div>
              <div v-for="item in activitiesData.activitiesPosE" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center">
              <div class="col-12 text-left mb-3">
                Atualização de Processos sem Booking
              </div>
              <div v-for="item in activitiesData.activitiesSemB" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-md-12 col-sm-12 col-12">
      <div class="card col-12">
        <div class="card-header border-0 mb-3">
          <div class="col-8">
            <h3>Importação Marítima</h3>
            <i class="expl">Atividades que geram follow up automático</i>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="row col-12 px-2 pt-4 pb-2 bt-1 m-0">
            <div class="col-4 px-0 text-center br1">
              <div v-for="item in activitiesData.activitiesIMFollowUpAuto" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-md-12 col-sm-12 col-12">
      <div class="card col-12">
        <div class="card-header border-0 mb-3">
          <div class="col-8">
            <h3>Importação Aérea</h3>
            <i class="expl">Atividades concluídas</i>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="row col-12 px-2 pt-4 pb-2 bt-1 m-0">
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Follow up
              </div>
              <div v-for="item in activitiesData.activitiesIAFollowup" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Enviar instrução de embarque ao agente
              </div>
              <div v-for="item in activitiesData.activitiesIAInstEmbarque" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Aguardando prontidão da mercadoria
              </div>
              <div v-for="item in activitiesData.activitiesIAAguMerc" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Confirmar coleta
              </div>
              <div v-for="item in activitiesData.activitiesIaConfCol" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Enviar draft para aprovação
              </div>
              <div v-for="item in activitiesData.activitiesIAEnvDraft" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Confirmar Booking
              </div>
              <div v-for="item in activitiesData.activitiesIAConfBooking" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Enviar pré-alerta ao cliente
              </div>
              <div v-for="item in activitiesData.activitiesIAPreAlerta" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Enviar apólice de seguro ao cliente
              </div>
              <div v-for="item in activitiesData.activitiesIAEnvApolice" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Confirmar embarque
              </div>
              <div v-for="item in activitiesData.activitiesIAConfEmbarque" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Inclusão do importador no Radar da Transportadora
              </div>
              <div v-for="item in activitiesData.activitiesIARadar" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Confirmar próximo trecho
              </div>
              <div v-for="item in activitiesData.activitiesIAConfTrecho" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Confirmar chegada no Brasil
              </div>
              <div v-for="item in activitiesData.activitiesIAConfChgdBR" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Vinculação da DTA
              </div>
              <div v-for="item in activitiesData.activitiesIAVincDTA" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Concessão da DTA
              </div>
              <div v-for="item in activitiesData.activitiesIAConcDTA" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Confirmar remoção para o destino final
              </div>
              <div v-for="item in activitiesData.activitiesIAConfRemFinal" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Confirmar chegada no destino final
              </div>
              <div v-for="item in activitiesData.activitiesIAConfChegada" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Confirmar a armazenagem no destino final
              </div>
              <div v-for="item in activitiesData.activitiesIAConfArmFinal" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-md-12 col-sm-12 col-12">
      <div class="card col-12">
        <div class="card-header border-0 mb-3">
          <div class="col-8">
            <h3>Exportação Aérea</h3>
            <i class="expl">Atividades concluídas</i>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="row col-12 px-2 pt-4 pb-2 bt-1 m-0">
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Abertura de processo
              </div>
              <div v-for="item in activitiesData.activitiesEAAbrPro" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Solicitar documentos da carga
              </div>
              <div v-for="item in activitiesData.activitiesEASolDoc" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Programar a coleta
              </div>
              <div v-for="item in activitiesData.activitiesEAProgCol" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Enviar draft para aprovação
              </div>
              <div v-for="item in activitiesData.activitiesEAEnvDraft" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Enviar DUE ao cliente
              </div>
              <div v-for="item in activitiesData.activitiesEAEnvDUE" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Confirmar Booking
              </div>
              <div v-for="item in activitiesData.activitiesEAConfBooking" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Confirmar embarque
              </div>
              <div v-for="item in activitiesData.activitiesEAConfEmbarque" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
            <div class="col-4 px-0 text-center br1">
              <div class="col-12 text-left mb-3">
                Confirmar entrega ao importador
              </div>
              <div v-for="item in activitiesData.activitiesEAConfEntregImpo" :key="item.qtd" class="row col-md-12 col-sm-12 col-12 bb1 p-1">
                <div class="col-2">
                  {{ item.qtd }}
                </div>
                <div class="col-10">
                  {{ item.nome }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import KpiService from '@/services/KPI/KpiService'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import UserBar from '@/components/Kpi/UserBar.vue'

export default {
  name: 'OperationalActivities',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.title') + ' ' + this.$i18n.t('kpi.analyst.operatinalList') + ' - %s'
    }
  },
  data () {
    return {
      period: '2022-01',
      OPAnalyst: false,
      isLoading: false,
      fullPage: true,
      activitiesData: []
    }
  },
  components: {
    Loading,
    UserBar
  },
  beforeMount () {

  },
  mounted () {
    this.getActivitiesList()
  },
  methods: {
    getActivitiesList () {
      this.isLoading = true

      KpiService.getProcessActivityList(this.period).then(res => {
        this.activitiesData = res.data
        this.isLoading = false
      })
    },
    updatePeriod (periodUpdated) {
      this.period = periodUpdated
    }
  },
  computed: {

  },
  watch: {
    period: {
      handler () {
        this.getActivitiesList()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.expl {font-weight: 200;color: #7a7777; font-size: 13px;}
.bb1 {border-bottom: 1px solid #e0e0e0;}
.br1 {border-right: 1px solid #e0e0e0;}

.text-center {margin-bottom: 50px;}
.mb-3 {font-size: 14px;font-weight: 600;}
</style>
